import React from "react";
import Layout from "../../components/Layout/Layout";
import styles from './newyear.module.scss';
import SEO from '../../components/seo';

const TwentyTwenty = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": 'Итоги года в мире фронтенда',
        "description": 'Этот год выдался тяжелым и в тоже время довольно интересным. Я проводил опросы в твиттере, собирал статистику и анализировал развитие мира фронтенда в этом году. Именно этой интересной информацией я хочу поделиться с вами. Надеюсь вам будет интересно.',
        "author": {
          "@type": "Person",
          "name": "Стас Багрецов"
        },  
        "publisher": {
          "@type": "Person",
          "name": "Стас Багрецов",
          "logo": {
            "@type": "ImageObject",
            "url": "https://stasonmars.ru/logo.png"
          }
        },
        "datePublished": '2020-12-22',
        "keywords": 'Новый год, frontend в 2020, итоги года frontend',
        "mainEntityOfPage": {
            "@Type": "WebPage",
            "@id": "https://stasonmars.ru/"
        }
      }
  return (<Layout>
    <SEO title='С Новым Годом!' description='Этот год выдался тяжелым и в тоже время довольно интересным. Я проводил опросы в твиттере, собирал статистику и анализировал развитие мира фронтенда в этом году. Именно этой интересной информацией я хочу поделиться с вами. Надеюсь вам будет интересно.' keywords='Новый год, frontend в 2020, итоги года frontend' schema={schema} />
    <article className={styles.newyear__container}>
        <header className={styles.newyear__container__header}>
            <h1>А вот и итоги уходящего 2020 года во фронтенде!</h1>
            <p className={styles.newyear__container__header__desc}>Этот год выдался тяжелым и в тоже время довольно интересным. Я проводил опросы <a href="https://twitter.com/stassonmars" className={styles.newyear__container__header__desc__link}>в твиттере</a>, собирал статистику и анализировал развитие мира фронтенда в этом году. Именно этой интересной информацией я хочу поделиться с вами. Надеюсь вам будет интересно.</p>
        </header>
        <section className={styles.newyear__container__content}>
            <h2>За год этот блог посетили:</h2>
            <h2>1 536 237 раз!</h2>
            <img className={styles.elka} src="/../elka.png" alt="новогодняя ёлка"/>
        </section>
        <section className={styles.newyear__container__content}>
            <p className={styles.newyear__container__content__warning}>⚠️ PS: если твиты не прогружаются, то подождите или обновите страничку</p>
            <h2>А теперь начинаем все самое самое интересное! Результаты опросов в твиттере!</h2>
            <h2>React! Всеми так горячо любимый</h2>
            <p>Вопрос, который мучил всех! Это конечно не читсая статистика, но показатели выглядат довольно правдиво</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Буду краток в опросе. Что вы предпочитаете или предпочли бы?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1261719344708554752?ref_src=twsrc%5Etfw">May 16, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>Не менее интересный вопрос, который уже многое говорит об актуальном стеке среди разработчиков</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Наверное самый животрепещущий вопрос среди React разработчиков</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1266443898601328641?ref_src=twsrc%5Etfw">May 29, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>А вот тут вообще интересно. Сколько бы не кричали со всех сторон про модули и тп, а чистый CSS с SASS все равно впереди</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">И всё же⚡️О давно наболевшем😵 Как вы предпочитаете стилизовать компоненты в React?🎨🖼🖌</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1286581870176669696?ref_src=twsrc%5Etfw">July 24, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>И немного интересного по этой же теме:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Интересно интересно. Вообще, используете схемы нэйминга в CSS? Типа БЭМ, например</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1315238819386466304?ref_src=twsrc%5Etfw">October 11, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>А вот тут на удивление выходит, что подавляющее большинство использует слабоиндексируемые SPA:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">На чём у вас проект(ы)? Или чему бы вы отдали предпочтение в зависимости от требований к актуальногму проекту?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1325766272718671874?ref_src=twsrc%5Etfw">November 9, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>То, что не используют jQuery это понятно. Но интересно то, что процент людей, которые начинали с React равен людям, которые ещё используют "старичка". Отличный показатель переходного поколения разработчиков</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Вы используете прямо сейчас jQuery в актуальных проектах?🥞</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1336640785123577856?ref_src=twsrc%5Etfw">December 9, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>Картина react разработчика получается такой. SPA, пишет на хуках, SASS с применением БЭМ</p>
        </section>
        <section className={styles.newyear__container__content}>
            <h2>А теперь немного про JavaScript</h2>
            <p>Самое интересное это то, как русскоязычная аудитория называет JS:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Кто из них ты?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1279357410583478273?ref_src=twsrc%5Etfw">July 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>Вот тут внезапненько:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Нехитрый💫, но довольно любознательный опрос❕, про типизацию и не только. Что используете?🪔</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1291755652604362757?ref_src=twsrc%5Etfw">August 7, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

        </section>
        <section className={styles.newyear__container__content}>
            <h2 style={{transform: 'rotate(-1.7deg)'}}>А теперь немного лирики про front-end, тут много интересного. Просто посмотрите!</h2>
            <p>Конечно же, кто круче?)</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">По просьбам трудящихся✊ Вопрос выжигающий землю🐲🔥Кто круче?🧐</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1308461975148650497?ref_src=twsrc%5Etfw">September 22, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>Тут всё понятно)))</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Вопрос на который нет ответа. Но мучения от него со мной буквально каждый день. Что беспощаднее? Типизация в JavaScript или пунктуация в великом и могучем?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1317034740696928257?ref_src=twsrc%5Etfw">October 16, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>А вот тут очень интересно расходятся мнения среди разработчиков. Лично я считаю, что лагоритмы нужны:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Великий опрос. Наболевший вопрос. Нужны ли фронт энд разработчику знания алгоритмов?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1330159463907667969?ref_src=twsrc%5Etfw">November 21, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>С тестированием всё понятно, как было годами, так и осталось:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Вы тестируете фронт?🥞</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1332715939587104772?ref_src=twsrc%5Etfw">November 28, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>Ну, тут всё очевидно</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Куда бы вы пошли после фронтенда?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1321075657678426113?ref_src=twsrc%5Etfw">October 27, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>А вот с мобильной разработкой всё как то не совсем очевидно:</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Просто интересно. Пробовали мобильную разработку?📱Заходили с фронта и как?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1304797112513814528?ref_src=twsrc%5Etfw">September 12, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <p>А вот ситуация с письмами меня очень удивила, вы только посмотрите!</p>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Обсуждали сегодня вёрстку писем и появился вот такой вот опрос. Очень интересно узнать о вашем опыте🎤 Вы когда-нибудь верстали HTML-письма?</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1294716293531271168?ref_src=twsrc%5Etfw">August 15, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </section>
        <section className={styles.newyear__container__content}>
            <h2>Ну, а теперь зарплаты. Цифры не точные, конечно. Но говорят сами за себя:</h2>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Всегда актуальный вопрос. ⁉️Сколько вы получаете, как front end разработчик? 💵Конечно же анонимно🤐</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1297202436864724996?ref_src=twsrc%5Etfw">August 22, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            <blockquote class="twitter-tweet"><p lang="ru" dir="ltr">Опрос довольно нескромный, но интересный. Спасибо <a href="https://twitter.com/Kick____Kick?ref_src=twsrc%5Etfw">@Kick____Kick</a> 💡за идею. Сколько вы ❕получаете/получали❕ до первой работы во фронтэнде? Само собой анонимно💶</p>&mdash; Stas Bagretsov🌲☃️⭐️ (@stassonmars) <a href="https://twitter.com/stassonmars/status/1299742974495141888?ref_src=twsrc%5Etfw">August 29, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </section>
        <section className={styles.newyear__container__content}>
            <h2 style={{transform: 'rotate(-1.7deg)', marginBottom: '50px'}}>С НОВЫМ ГОДОМ!</h2>
        </section>
    </article>
  </Layout>)
}

export default TwentyTwenty;
